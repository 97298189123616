<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tradeTariffApp.wto_rto_info_management') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Country" vid="current_signatories" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="current_signatories"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffApp.current_signatories')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.current_signatories"
                  :options="tradeTariffCountryList"
                  id="current_signatories"
                  @change="findCountryData()"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="rta name" vid="rta_name" v-slot="{ errors }">
                  <b-form-group
                      label-for="rta_name"
                  >
                  <template v-slot:label>
                      {{ $t('tradeTariffApp.rta_name') }}
                      </template>
                      <b-form-input
                          v-model="search.rta_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="9">
          </b-col>
          <b-col sm="3" style="text-align: right;">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('tradeTariffApp.wto_rto_info_management') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <!-- <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" /> -->
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + 1) }}
                  </template>
                  <template v-slot:cell(current_signatories)="data">
                    <span v-for="(dataValue,index) in data.item.current_signatories" :key="index">
                      {{ getCurrentSignatoriesName(dataValue) }},
                    </span>
                  </template>
                  <template v-slot:cell(coverage_of_agreement_id)="data">
                    {{ getCoverageOfAgreementName(data.item.coverage_of_agreement_id) }}
                  </template>
                  <template v-slot:cell(status_of_agreement_id)="data">
                    {{ getStatusOfAgreementName(data.item.status_of_agreement_id) }}
                  </template>
                  <template v-slot:cell(attachment_main)="data">
                    <span v-if="data.item.attachment_main !== null">
                    <a target="_blank" class="btn btn-primary mr-2" :href="tradeTariffServiceBaseUrl+'download-attachment?file='+data.item.attachment_main" title="Application Attachment"><i class="ri-file-download-line"></i>&nbsp;
                    </a>
                    </span>
                    <span v-else>N/A</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { externalCountryTariffList, countryInformationManagementList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        country_id: 0,
        current_signatories: 0,
        rta_name: '',
        limit: 10
      },
      items: [],
      itemsData: [],
      applicationList: [],
      userDetails: [],
      warehouseList: [],
      viewitemId: 0,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'externalTradeTraiff.tariff_description', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '55%' } },
        { labels: 'externalTradeTraiff.tariff_result', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'globalTrans.attachment', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 4, thStyle: { width: '15%' } }
      ],
      actions: {
        edit: false,
        details: true,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.searchData()
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.changeEnBn()
        }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    tradeTariffCountryList: function () {
      const objectData = this.$store.state.ExternalUserService.tradeTariff.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
        })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'rta_name_bn' },
          // { key: 'current_signatories' },
          // { key: 'coverage_of_agreement_id' },
          { key: 'status_of_agreement_id' },
          { key: 'attachment_main' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'rta_name_en' },
          // { key: 'current_signatories' },
          // { key: 'coverage_of_agreement_id' },
          { key: 'status_of_agreement_id' },
          { key: 'attachment_main' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('tradeTariffApp.wto_rto_info_management') + ' ' + this.$t('globalTrans.entry') : this.$t('tradeTariffApp.wto_rto_info_management') + ' ' + this.$t('globalTrans.modify')
    },
    viewTitle () {
      return this.$t('tradeTariffApp.wto_rto_info_management') + ' ' + this.$t('globalTrans.details')
    }
  },
  methods: {
    changeEnBn () {
       this.tradeTariffCountryList()
    },
    getCurrentSignatoriesName (id) {
        const cateObj = this.$store.state.ExternalUserService.tradeTariff.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        } else {
        return ''
        }
    },
    getCoverageOfAgreementName (id) {
        const cateObj = this.$store.state.ExternalUserService.tradeTariff.commonObj.coverageOfAgreementList.find(item => item.value === parseInt(id))
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        } else {
          return ''
        }
    },
    getStatusOfAgreementName (id) {
        const cateObj = this.$store.state.ExternalUserService.tradeTariff.commonObj.statusOfAgreementList.find(item => item.value === parseInt(id))
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        } else {
          return ''
        }
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, externalCountryTariffList, params)
      if (result.success) {
        this.userList = result.userDetails
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async findCountryData () {
      this.search.country_id = this.search.current_signatories
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, countryInformationManagementList, params)
      if (result.success) {
        this.itemsData = result.data.data[0]
      } else {
        this.itemsData = []
      }
    }
  }
}
</script>
